import React from "react";
import OpengraphReactComponent from "opengraph-react";
import { Container, Row, Col, Hidden  } from 'react-grid-system';


class Header extends React.Component {
    render() {
      return (
        <header>
          <Container fluid>
            <Row>
              <Hidden md sm>
              <Col lg={3} md={0} xl={3} xxl={3} xxxl={3}>
                
                  <div><p></p></div>
                
              </Col>
              </Hidden>
              <Col lg={3} md={6} xl={3} xxl={3} xxxl={3} className='window'>
                <div className='border'>
                  <div className='title'>
                    <h1>Welcome banner</h1>
                  </div>
                  <div className='content test'>
                    <p>Hi, my name is Gabriel; I publish information about ongoing or finished projects on this website. Please take a look and see if you find something you like!</p>
                    <div className="line"></div>
                    <p>Hej, jag heter Gabriel; Jag publicerar information om pågående eller avslutade projekt på denna webbplats. Ta gärna en titt och se om du hittar något du gillar!</p>
                  </div>
                </div>
              </Col>
              <Col lg={3} md={6} xl={3} xxl={3} xxxl={3} className='window'>
                <div className='border'>
                  <div className='title'>
                    <h1>Github</h1>
                  </div>
                  <div className='content opengraph'>
                    <OpengraphReactComponent site="https://github.com/SwoMancer" size="small" appId='4a365c8b-5a98-49cc-8835-dc46019ecc97' />
                  </div>
                </div>
              </Col>
              <Col lg={3} md={6} xl={3} xxl={3} xxxl={3} className='window'>
                <div className='border'>
                  <div className='title'>
                    <h1>Links</h1>
                  </div>
                  <div className='content'>
                    <p>You can find me here on <a href="https://www.linkedin.com/in/gabriel-wrede-b15ba9278/" target="_blank" rel="noreferrer">Linkedin</a>, <a href="https://github.com/SwoMancer" target="_blank" rel="noreferrer">Github</a> and <a href="https://www.facebook.com/gabriel.wrede" target="_blank" rel="noreferrer">Facebook</a>.</p>
                    <p>You can find my Minecraft mods on <a href="https://modrinth.com/user/swomancer" target="_blank" rel="noreferrer">Modrinth</a> when I get the time to upload them.</p>
                    <div className="line"></div>
                    <p>For my friends and people who want to find games-related links on this site. Here is the Minecraft server map for <a href="https://wrede.blue/mcmap/" target="_blank" rel="noreferrer">the overworld</a>, <a href="https://wrede.blue/mcmap/nether/" target="_blank" rel="noreferrer">the nether</a> and <a href="https://wrede.blue/mcmap/end/" target="_blank" rel="noreferrer">the end</a>. Add your username to the <s>allowlist manager</s> to play on the server.</p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </header>
      );
    }
}
export default Header;