import React, {useState, useEffect} from 'react';
import { Container, Row, Col, Hidden  } from 'react-grid-system';
import ImageGallert from 'react-image-gallery';
import moment from 'moment';



const Main = () => {
  const [posts, setPosts] = useState([]);
  var test = [];
  useEffect(() => {
     fetch('https://wrede.blue/API/Homepage/api/HomePage/cards')
        .then((response) => response.json())
        .then((data) => {
          for (let i = 0; i < data.length ; i++){
            for (let j = 0; j < data[i].imageLinks.length ; j++){
              test = [...test, {original: data[i].imageLinks[j], originalHeight: 300}]
            }
            data[i].imageLinks = test;
            test = [];
            data[i].date = new Date(data[i].date).toLocaleDateString(
              'en-gb',
              {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
              }
            );
          }
           setPosts(data);
        })
        .catch((err) => {
           console.log(err.message);
        });
  }, []);

return (
  <article>
    {posts.map((post) => {
      return (
        <Container className='card-post' fluid key={post.id}>
            <Row>
              <Hidden sm>
              <Col lg={1} md={1} sm={0}>

              </Col>
              </Hidden>
              <Col lg={4} md={4} sm={5} >
                <div className='border background changling'>
                  <div className='fake-top'></div>
                  <ImageGallert items={post.imageLinks}  autoPlay={true} showFullscreenButton={false}/>
                </div>
              </Col>
              <Col lg={6} md={6} sm={7}>
                <div className='border background text-box changling'>
                  <div className='fake-top'></div>

                  <h1>{post.title}</h1>
                  <div className='dangerously-box' dangerouslySetInnerHTML={{ __html: post.text }} />
                  <div className='date'><p>{post.date}</p></div>
                </div>              
                </Col>
              <Hidden sm>
              <Col lg={1} md={1} sm={0}>
                
              </Col>
              </Hidden>
            </Row>
          </Container>
      );
    })}
  </article>
);
};

export default Main;

/*
<div key={post.id}>
          <h6>{post.title}</h6>
          <p>{post.text}</p>
        </div>
class Main extends React.Component {

    render() {
      return (
        <article>
          
        </article>
      );
    }
}
export default Main;
*/
/*
<ul>
            {seasons.map((season) => (
            <li key={season.id}>{season}</li>
            ))}
          </ul>
*/

/*

<Container fluid>
            <Row>
              <Hidden sm>
              <Col lg={1} md={1} sm={0}>

              </Col>
              </Hidden>
              <Col lg={5} md={5} sm={6} >
                <div className='border background border-padding'>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent ullamcorper suscipit dolor eget molestie. Morbi erat odio, mattis nec viverra id, blandit id urna. Proin ac lorem lectus. Duis eleifend odio nunc, eu euismod sapien molestie ut. Curabitur diam ante, vestibulum nec felis quis, facilisis mattis dolor. Nullam mi justo, mollis vel nibh a, egestas cursus orci. Maecenas sit amet porttitor ligula. Proin accumsan condimentum mi eu lobortis. Nunc luctus elit convallis augue fermentum, at hendrerit velit suscipit.</p>
                </div>
              </Col>
              <Col lg={5} md={5} sm={6}>
                <div className='border background border-padding'>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent ullamcorper suscipit dolor eget molestie. Morbi erat odio, mattis nec viverra id, blandit id urna. Proin ac lorem lectus. Duis eleifend odio nunc, eu euismod sapien molestie ut. Curabitur diam ante, vestibulum nec felis quis, facilisis mattis dolor. Nullam mi justo, mollis vel nibh a, egestas cursus orci. Maecenas sit amet porttitor ligula. Proin accumsan condimentum mi eu lobortis. Nunc luctus elit convallis augue fermentum, at hendrerit velit suscipit.</p>
                </div>              </Col>
              <Hidden sm>
              <Col lg={1} md={1} sm={0}>
                
              </Col>
              </Hidden>
            </Row>
          </Container>

*/