import './App.scss';

import Header from './component/Header';
import Main from './component/Main';
//import Body from './component/Body'
//import Card from './component/Card'
//import bg from './img/Magic.PNG'
import BackgroundMagic from './component/BackgroundMagic';


import React from "react";

function App() {
  return (
    <div className="App">
      <div className="lines"></div>
      <Header className="topzoon"/>
      <Main />
      <BackgroundMagic className="bgzoon" />
    </div>
  );
}

export default App;
/*
<Body>
        <Card title="Music Jammer" text="Is an application for Android that allows you to mix sounds to create something resembling music or background sounds. The application is built with C#, Java and Xamarin." />
        <Card title="Xamarin recipes finder" text="Is an application for Android, IOS and Windows that allows you to find recipes for food with the help of choosing the ingredients you want in the dish. The application is built with C# and Xamarin." />
        <Card title="Expo recipes finder" text="Is a responsive website that allows you to find recipes for food with the help of choosing the ingredients you want in the dish. The website uses React, Expo and JavaScript." />
        <Card title="UPM400" text="It is a group project from the software development course at University West, Trollhättan. We performed software tests and created a web API communicating with other projects in the same class." />
        <Card title="Fiskekompaniet website" text="A job that I did for a long time where I created a page with Bootstrap, SASS, HTML and JavaScript. The page is simple as the customer wants to be able to change the website themselves afterwards if modifications must be made after the project is done." />
        <Card title="Restful- and soap web API" text="You can find some of my API:s here https://wrede.blue/api/" />
        <Card title="t" text="t" />


      </Body>
*/