import React from "react";
import { Container, Row, Col, Hidden  } from 'react-grid-system';
import bgn from '../img/Magic.PNG'
import bgr from '../img/Magic-revers.PNG'
import flower from '../img/flower.PNG'

class BackgroundMagic extends React.Component {
    render() {
      return (
        <div className="bg-box"> 
          <Container fluid className="vore">
            <Row>
              <Col className="over" xs={6} sm={6} md={6} lg={6} xl={4} xxl={4} xxxl={4}>
                <img src={flower} className='flower flower-top-right' alt='bg'/>  
                <img src={flower} className='flower flower-bottom-right' alt='bg'/>  

                <img src={bgn} className='bg1' alt='bg'/>  
              </Col>
              <Hidden xs lg md sm>
                <Col className="over" sm={0} md={0} lg={0}xl={4} xxl={4} xxxl={4} >
                    
                </Col>
              </Hidden>
              <Col className="over" xs={6} sm={6} md={6} lg={6} xl={4} xxl={4} xxxl={4}>
                <img src={flower} className='flower flower-top-left' alt='bg'/>  
                <img src={flower} className='flower flower-bottom-left' alt='bg'/>  

                <img src={bgr} className='bg2' alt='bg'/>
              </Col>
            </Row>
          </Container>
        </div>
      );
    }
}
export default BackgroundMagic;